.cookie-consent {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f8f9fa; /* Jasne tło */
    color: #343a40; /* Ciemny tekst */
    text-align: center;
    padding: 20px;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
  }
  
  .cookie-consent__content {
    max-width: 960px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .cookie-consent__actions {
    margin-top: 10px;
    display: flex;
    gap: 10px;
  }
  
  .cookie-consent__button {
    background-color: #007bff; /* Niebieskie przyciski */
    color: #fff;
    border: none; 
    cursor: pointer;
    border-radius: 5px;
  }
  
  .cookie-consent__button--decline {
    background-color: #6c757d; /* Szare przyciski */
  }
  
  .cookie-consent__button--accept {
    background-color: #28a745; /* Zielone przyciski */
  }
  
  .cookie-consent__button--save {
    background-color: #17a2b8; /* Turkusowe przyciski */
  }
  
  .cookie-consent__button:hover {
    opacity: 0.8;
  }
  
  .cookie-consent__preferences {
    margin-top: 10px;
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .preferences-center__options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  
  label {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
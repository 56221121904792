/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
.homePageDesc{
  color: 'rgb(255, 255, 255)';
  font-family: 'PT Serif, serif';
  font-weight: 500;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tour-link:hover {
  color: rgb(10, 10, 10); /* Zmienia kolor tekstu na szary przy najechaniu */
}
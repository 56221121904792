.form-container {
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-group {
    margin-bottom: 20px;
}

.form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.schedule-control {
    display: flex;
    align-items: center;
}

.schedule-control select {   
    width: 150px !important; 
}
.schedule-control .time-input {
    width: 80px !important;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.add-btn {
    margin-left: 10px !important;
    padding: 6px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100px !important;
}

.schedule-list ul {
    list-style-type: none;
    padding: 0;
}
.schedule-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 10px;
}
.schedule-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.delete-btn {
    padding: 3px 8px;
    background-color: #dc3545 !important;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100px !important;
    margin-left: 10px !important;
    
}

.add-tour .submit-btn {
    
    background-color: rgb(0, 121, 107);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100px !important;
    
}


.add-tour .form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #000;
    
}
.schedule-control .form-input{
    width: 100%;
    margin-right: 10px;
}
/* W GuideAddTourForm.css */
.autosuggest-container {
    position: relative;
}

.autosuggest-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    z-index: 9999;
}

.autosuggest-suggestion {
    padding: 10px;
    cursor: pointer;
}

.autosuggest-suggestion:hover {
    background-color: #f0f0f0;
}



.tours-list-mobile .ant-table-thead {
    display: none;
}

.tours-list-mobile .ant-table-cell {
    padding: 10px;
}

.tours-list-mobile .details-container {
    display: flex;
    flex-direction: column;
}

.tours-list-mobile .image-prices-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; /* Dodajemy to, aby ceny były wyrównane do prawej */
    width: 100%;
}

.tours-list-mobile .info-container {
    margin-bottom: 10px;
}

.tours-list-mobile .prices-container {
    margin-left: auto;
    text-align: right; /* Dodajemy to, aby tekst cen był wyrównany do prawej */
}

.tours-list-mobile .tour-title {
    font-size: 18px;
    font-weight: bold;
}

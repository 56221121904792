.tours-list .ant-table-thead {
    display: none;
}

.tours-list .ant-table-cell {
    padding: 10px;
    vertical-align: top; /* Wyrównanie zawartości do góry */
}

.tours-list .details-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Wyrównanie elementów do góry */
}

.tours-list .tour-title {
    font-size: 18px;
    font-weight: bold;
}

.tours-list .mobile-hidden {
    display: none;
}

.tours-list .ant-table-row {
    cursor: pointer; /* Zmienia kursor na pointer dla wierszy */
}

@media (min-width: 768px) {
    .tours-list .mobile-hidden {
        display: block;
    }
}

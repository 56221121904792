.blog h1 {
    font-size: 30px !important; 
    text-align: center;
    color: #333 !important; 
  }
  .blog  h2 {
    font-size: 20px !important;
    text-align: left;
    color: #333 !important; 
  }
  .blog  p {
    font-size: 18px !important;
    padding-bottom: 10px;
  }
  .blog  span {
    font-size: 16px ;
    color: #747171 !important; 

  }
  .blog {
    font-size: 20px;
    max-width: 800px;
    margin: auto;
    
  }
  .blog-articale {
    font-size: 20px;
    max-width: 800px;
    margin: auto;
    
  }
  .blog-article h2 { 
    font-size: 16px;
    
  }
  .blog-articale-container{
    display: flex;  /* Ustawienie układu flexbox dla kontenera */
    width: 100%;  /* Kontener zajmuje 100% szerokości */

  }
  .blog-articale1{
    flex: 0 0 100%;
    padding: 10px;

  }
  .blog-articale2{
    flex: 0 0 0%;
    padding-top: 180px;
  }
  .blog-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    /* Maksymalna szerokość, która pomieści 5 kart po 200px + odstępy */
}
   .blog-card-locations {
    flex: 1 1 calc(49%);   
    height: 500px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
    text-decoration: none;
    color: black;
    margin: 1px;
}
.blog-card-locations  img {
    width: 100%;
    height: 55%;
    object-fit: cover;
}
.blog-card-locations  p {
    margin: 0;
    padding: 1px;
    font-size: 15px;
}
.blog-card-locations  h2 {
  text-align: center;
  padding: 1px;
  font-size: 15px;
}
.blog img {
  width: 100%;
  height: 55%;
  object-fit: cover;
}



.blog-article1 img {
  width: 100%; /* Skaluje obraz do szerokości kontenera */
  height: 100% !important; /* Zachowuje proporcje obrazu */
  max-width: 100%; /* Dodatkowe zabezpieczenie */
  margin: 20px 0; /* Marginesy wokół obrazka */
  object-fit: cover;

}
